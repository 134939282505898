import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';
import {
    getRetainerOverviewSuccess,
    getRetainerOverviewFailure,

    resetRetainerOverviewSuccess,
    resetRetainerOverviewFailure,

    resetTicketsSuccess,
    resetTicketsFailure,

    updateProjectSuccess,
    updateProjectFailure,

    getTicketsSuccess,
    getTicketsFailure,

    getSprintDataFailure,
    getSprintDataSuccess,

    getBillingPeriodDataFailure,
    getBillingPeriodDataSuccess,

    getSprintPlanDataFailure,
    getSprintPlanDataSuccess,

    updateDataSourceFailure,
    updateDataSourceSuccess,

    updateBillingPeriodDataFailure,
    updateBillingPeriodDataSuccess,

    getBugSummarySuccess,
    getBugSummaryFailure,

    getBugsSuccess,
    getBugsFailure,

    getPevInitialValuesFailure,
    getPevInitialValuesSuccess,

    getPevLatestSnapshotFailure,
    getPevLatestSnapshotSuccess,

    getNewPevSnapshotFailure,
    getNewPevSnapshotSuccess,

    deleteBillingPeriodDataFailure,
    deleteBillingPeriodDataSuccess,

    getBugTicketBreakdownSuccess,
    getBugTicketBreakdownFailure,

    resetBugSummarySuccess,
    resetBugSummaryFailure,

    updateComponentsSuccess,
    updateComponentsFailure,

    updateBudgetsSuccess,
    updateBudgetsFailure,

    exportSpecDocSuccess,
    exportSpecDocFailure,

    getPullRequestsSuccess,
    getPullRequestsFailure,
} from './agileProjectActions';
import { getDashProjectsRequest } from '../dashboard/dashboardActions';

const {
    GET_RETAINER_OVERVIEW_REQUEST,
    RESET_RETAINER_OVERVIEW_REQUEST,
    RESET_BUG_SUMMARY_REQUEST,
    RESET_TICKETS_REQUEST,
    UPDATE_PROJECT_REQUEST,
    GET_TICKETS_REQUEST,
    GET_SPRINT_DATA_REQUEST,
    GET_BILLING_PERIOD_DATA_REQUEST,
    GET_SPRINT_PLAN_DATA_REQUEST,
    UPDATE_DATA_SOURCE_REQUEST,
    UPDATE_BILLING_PERIOD_DATA_REQUEST,
    GET_BUG_SUMMARY_REQUEST,
    GET_PEV_INITIAL_VALUES_REQUEST,
    GET_PEV_LATEST_SNAPSHOT_REQUEST,
    GET_NEW_PEV_SNAPSHOT_REQUEST,
    DELETE_BILLING_PERIOD_DATA_REQUEST,
    GET_BUG_TICKET_BREAKDOWN_REQUEST,
    GET_BUGS_REQUEST,
    UPDATE_COMPONENTS_REQUEST,
    UPDATE_BUDGETS_REQUEST,
    EXPORT_SPEC_DOC_REQUEST,
    GET_PULL_REQUESTS_REQUEST,
} = require('./agileProjectActions').constraints;

function* getRetainerOverview({ payload }) {
    const { projectId } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/agile/project/${projectId}/retainerOverview`,
        });
        if (response.data) {
            yield put(getRetainerOverviewSuccess(response.data));
        }

        message.success('Project data fetched');
    } catch (e) {
        yield put(getRetainerOverviewFailure(e.response ? e.response.data.message : e));
    }
}

function* updateOrCreateProject({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/project/update',
            body: { ...payload.data },
        });
        yield put(updateProjectSuccess(response.data));
        if (response?.data?.success) {
            if (response.data?.overview) {
                yield put(getRetainerOverviewSuccess(response.data.overview));
            }
        }
        message.success(response?.data?.success);
    } catch (e) {
        yield put(updateProjectFailure(e.response ? e.response.data.message : e));
    }
}

function* resetRetainerOverview() {
    try {
        yield put(resetRetainerOverviewSuccess());
    } catch (e) {
        yield put(resetRetainerOverviewFailure(e.response ? e.response.data.message : e));
    }
}

function* resetBugSummary() {
    try {
        yield put(resetBugSummarySuccess());
    } catch (e) {
        yield put(resetBugSummaryFailure(e.response ? e.response.data.message : e));
    }
}

function* resetTickets() {
    try {
        yield put(resetTicketsSuccess());
    } catch (e) {
        yield put(resetTicketsFailure(e.response ? e.response.data.message : e));
    }
}

function* getTickets({ payload }) {
    const { projectCode, projectName, page = 1, pageSize = 10, filters = null, sorters = null } = payload;
    try {
        const serializedFilters = encodeURIComponent(JSON.stringify(filters));
        const serializedSorters = encodeURIComponent(JSON.stringify(sorters));
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/agile/project/${projectCode}/${projectName}/tickets?pageSize=${pageSize}&page=${page}&filters=${serializedFilters}&sorters=${serializedSorters}`,
        });
        yield put(getTicketsSuccess(response.data));
    } catch (e) {
        yield put(getTicketsFailure(e.response ? e.response.data.message : e));
    }
}

function* getPullRequests({ payload }) {
    const { projectId, page = 1, pageSize = 10, filters = null, sorters = null } = payload;
    try {
        const serializedFilters = encodeURIComponent(JSON.stringify(filters));
        const serializedSorters = encodeURIComponent(JSON.stringify(sorters));
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/agile/project/${projectId}/pull-requests?pageSize=${pageSize}&page=${page}&filters=${serializedFilters}&sorters=${serializedSorters}`,
        });
        yield put(getPullRequestsSuccess(response.data));
    } catch (e) {
        yield put(getPullRequestsFailure(e.response ? e.response.data.message : e));
    }
}

function* getSprintData({ payload }) {
    const { projectCode, projectName, sprintId, projectId, page = 1 } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `agile/project/${projectCode}/${projectName}/tickets?sprintId=${sprintId}&pageSize=1000&page=${page}`,
        });
        yield put(getSprintDataSuccess({ sprintId, data: response.data, projectId }));
    } catch (e) {
        yield put(getSprintDataFailure(e.response ? e.response.data.message : e));
    }
}

function* getBillingPeriodData({ payload }) {
    const { projectCode, projectName, period, projectId, periodId, page = 1, pageSize = 10 } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `agile/project/${projectCode}/${projectName}/tickets?periodIds=${periodId.join(',')}&pageSize=${pageSize}&page=${page}`,
        });
        yield put(getBillingPeriodDataSuccess({ period, data: response.data, projectId, periodId }));
    } catch (e) {
        yield put(getBillingPeriodDataFailure(e.response ? e.response.data.message : e));
    }
}

function* getSprintPlanData({ payload }) {
    const { projectCode, projectName, sprintId, projectId, page = 1, pageSize = 100 } = payload;

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `agile/project/${projectCode}/${projectName}/tickets?sprintPlanId=${sprintId}&pageSize=${pageSize}&page=${page}`,
        });
        yield put(getSprintPlanDataSuccess({ sprintId, data: response.data, projectId }));
    } catch (e) {
        yield put(getSprintPlanDataFailure(e.response ? e.response.data.message : e));
    }
}

function* updateDataSources({ payload }) {
    const { projectCode, projectName, parsedId } = payload;

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `project/${projectCode}/${projectName}/updateDataSources`,
        });

        yield put(updateDataSourceSuccess({ ...response.data, parsedId }));

        if (response?.data?.success) {
            yield put(getDashProjectsRequest());
        }
        message.success(response?.data?.success);
    } catch (e) {
        yield put(updateDataSourceFailure(e.response ? e.response?.data?.message : e));
    }
}

function* updateBillingPeriod({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/project/billingsUpdate',
            body: { ...payload.data },
        });
        yield put(updateBillingPeriodDataSuccess(response.data));

        if (response?.data?.success) {
            yield put(getRetainerOverviewSuccess(response.data.overview));
        }
        message.success(response?.data?.success);
    } catch (e) {
        yield put(updateBillingPeriodDataFailure(e.response ? e.response.data.message : e));
    }
}

function* getPevInitialValues({ payload }) {
    const { projectId } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/agile/project/${projectId}/pevInitialValues`,
        });
        yield put(getPevInitialValuesSuccess(response.data));
    } catch (e) {
        yield put(getPevInitialValuesFailure(e.response ? e.response.data.message : e));
    }
}

function* getPevLatestSnapshot({ payload }) {
    const { projectId } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/agile/project/${projectId}/pevLatestSnapshot`,
        });
        yield put(getPevLatestSnapshotSuccess(response.data));
    } catch (e) {
        yield put(getPevLatestSnapshotFailure(e.response ? e.response.data.message : e));
    }
}

function* getNewPevSnapshot({ payload }) {
    const { projectId } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/agile/project/${projectId}/newPevSnapshot`,
        });
        yield put(getNewPevSnapshotSuccess(response.data));
    } catch (e) {
        yield put(getNewPevSnapshotFailure(e.response ? e.response.data.message : e));
    }
}

function* deleteBillingPeriod({ payload }) {
    const { billingPeriodId } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `project/billingPeriod/${billingPeriodId}/delete`,
        });
        yield put(deleteBillingPeriodDataSuccess({ ...response, billingPeriodId }));
        message.success(response?.data?.success);
    } catch (e) {
        yield put(deleteBillingPeriodDataFailure(e.response ? e.response.data.message : e));
    }
}

function* getBugSummary({ payload }) {
    const { projectId, fixVersions } = payload;
    const fixVersionsString = JSON.stringify(fixVersions);
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/project/${projectId}/bugSummary?fixVersions=${fixVersionsString}`,
        });
        yield put(getBugSummarySuccess(response.data));
    } catch (e) {
        yield put(getBugSummaryFailure(e.response ? e.response.data.message : e));
    }
}

function* getBugs({ payload }) {
    const { projectId, fixVersion } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/project/${projectId}/fixVersion/${fixVersion}/bugs`,
        });
        yield put(getBugsSuccess(response.data));
    } catch (e) {
        yield put(getBugsFailure(e.response ? e.response.data.message : e));
    }
}

function* getBugTicketBreakdown({ payload }) {
    const { projectId, fixVersions } = payload;
    const fixVersionsString = JSON.stringify(fixVersions);
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/project/${projectId}/fixVersion/bugsTicketsBreakdown?fixVersions=${fixVersionsString}`,
        });
        yield put(getBugTicketBreakdownSuccess(response.data));
    } catch (e) {
        yield put(getBugTicketBreakdownFailure(e.response ? e.response.data.message : e));
    }
}

function* updateComponents({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/project/update/components',
            body: { data: payload },
        });
        yield put(updateComponentsSuccess(response?.data));
        message.success(response?.data?.success);
    } catch (e) {
        yield put(updateComponentsFailure(e.response ? e.response.data.message : e));
    }
}

function* updateBudgets({ payload }) {
    try {
        const { projectId } = payload;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/project/${projectId}/updateBudgets`,
            body: { data: payload?.budgets },
        });
        yield put(updateBudgetsSuccess(response?.data));
        message.success(response?.data?.success);
    } catch (e) {
        yield put(updateBudgetsFailure(e.response ? e.response.data.message : e));
    }
}

function* exportSpecDoc({ payload }) {
    try {
        const { projectId, fixVersions } = payload;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/project/${projectId}/exportSpecDoc`,
            body: { fixVersions },
        });
        yield put(exportSpecDocSuccess(response?.data));
        message.success(response?.data?.success);
    } catch (e) {
        yield put(exportSpecDocFailure(e.response ? e.response.data.message : e));
    }
}

export default function* agileProjectSagas() {
    yield* [
        takeEvery(GET_RETAINER_OVERVIEW_REQUEST, getRetainerOverview),
        takeEvery(UPDATE_PROJECT_REQUEST, updateOrCreateProject),
        takeEvery(RESET_RETAINER_OVERVIEW_REQUEST, resetRetainerOverview),
        takeEvery(RESET_BUG_SUMMARY_REQUEST, resetBugSummary),
        takeEvery(RESET_TICKETS_REQUEST, resetTickets),
        takeEvery(GET_BUGS_REQUEST, getBugs),
        takeEvery(GET_PULL_REQUESTS_REQUEST, getPullRequests),
        takeEvery(GET_TICKETS_REQUEST, getTickets),
        takeEvery(GET_SPRINT_DATA_REQUEST, getSprintData),
        takeEvery(GET_BILLING_PERIOD_DATA_REQUEST, getBillingPeriodData),
        takeEvery(GET_SPRINT_PLAN_DATA_REQUEST, getSprintPlanData),
        takeEvery(UPDATE_DATA_SOURCE_REQUEST, updateDataSources),
        takeEvery(UPDATE_BILLING_PERIOD_DATA_REQUEST, updateBillingPeriod),
        takeEvery(GET_BUG_SUMMARY_REQUEST, getBugSummary),
        takeEvery(GET_PEV_INITIAL_VALUES_REQUEST, getPevInitialValues),
        takeEvery(GET_PEV_LATEST_SNAPSHOT_REQUEST, getPevLatestSnapshot),
        takeEvery(GET_NEW_PEV_SNAPSHOT_REQUEST, getNewPevSnapshot),
        takeEvery(DELETE_BILLING_PERIOD_DATA_REQUEST, deleteBillingPeriod),
        takeEvery(GET_BUG_SUMMARY_REQUEST, getBugSummary),
        takeEvery(GET_BUG_TICKET_BREAKDOWN_REQUEST, getBugTicketBreakdown),
        takeEvery(UPDATE_COMPONENTS_REQUEST, updateComponents),
        takeEvery(UPDATE_BUDGETS_REQUEST, updateBudgets),
        takeEvery(EXPORT_SPEC_DOC_REQUEST, exportSpecDoc),
    ];
}
