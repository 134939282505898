import { persistCombineReducers, createTransform } from 'redux-persist';
import { isEmpty } from 'underscore';

import { message } from 'antd';
import storage from 'redux-persist/lib/storage';
import global from './global/globalReducer';
import programmeOverview from './programmeOverview/programmeOverviewReducer';
import projectAccuracy from './projectAccuracy/projectAccuracyReducer';
import agileProject, { initialState as agileState } from './agileProject/agileProjectReducer';
import dashboard from './dashboard/dashboardReducer';
import user from './user/userReducer';
import projectSettings from './projectSettings/projectSettingsReducer';

const stateResets = {
    agileProject: {
        initialState: agileState,
    },
};

/**
 * Takes a state object and a key list. This will revert the state values for the given key list to the initial state.
 * If isWhitelist is true, only the keys in the whitelist will be retained, and all other values will be reverted.
 * @param {object} state The initial state object used to revert excluded values
 * @param {array} list List of keys to apply to the state
 * @param {boolean} isWhitelist Should the list be treated as a whitelist instead of a blacklist?
 * @returns object
 */
const resetState = (state, list, isWhitelist = false) => {
    const obj = state.toJSON();
    return Object.keys(obj)
        .filter(key => (isWhitelist ? !list.includes(key) : list.includes(key)))
        .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
};

/**
 * Creates a state transform to perform granular state resets based on a blacklist or whitelist.
 */
const stateResetTransform = createTransform(
    null,
    (state, key) => {
        const { initialState: firstState, blacklist, whitelist } = stateResets[key];

        if (blacklist && !isEmpty(blacklist)) {
            return { ...state, ...resetState(firstState, blacklist) };
        } if (whitelist && !isEmpty(whitelist)) {
            return { ...state, ...resetState(firstState, whitelist, true) };
        }

        return state;
    },
    { whitelist: Object.keys(stateResets) },
);

const config = {
    key: 'primary',
    storage,
    transforms: [stateResetTransform],
    whitelist: [
        'agileProject',
    ],
};

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = persistCombineReducers(config, {
    // ... your other reducers here ...
    global,
    programmeOverview,
    projectAccuracy,
    agileProject,
    user,
    dashboard,
    projectSettings,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        if (message && message.error) {
            message.error(action.payload);
        }
        if (state.user.isLoggedIn) {
            return {
                ...state,
                user: state.user, // Keep the user state as it is
            };
        }

    }
    return appReducers(state, action);
};

export default rootReducer;
